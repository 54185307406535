<template>
  <div class="page_daInquiry">
    <div v-if="info.mailing_num" class="cell_group">
      <div class="cell_item">
        <div class="item_title">学号</div>
        <div class="item_value">{{ info.STU_NUM }}</div>
      </div>
      <div class="cell_item">
        <div class="item_title">姓名</div>
        <div class="item_value">{{ info.STU_NAME }}</div>
      </div>
      <div class="cell_item">
        <div class="item_title">档案接收单位名称</div>
        <div class="item_value">{{ info.recipient }}</div>
      </div>
<!--      <div class="cell_item">-->
<!--        <div class="item_title">档案接收单位邮编</div>-->
<!--        <div class="item_value">{{ info.STU_NAME }}</div>-->
<!--      </div>-->
      <div class="cell_item">
        <div class="item_title">档案接收单位地址</div>
        <div class="item_value">{{ info.send_address }}</div>
      </div>
      <div class="cell_item">
        <div class="item_title">接收人联系电话</div>
        <div class="item_value">{{ info.contact_number }}</div>
      </div>
<!--      <div class="cell_item">-->
<!--        <div class="item_title">手机号码</div>-->
<!--        <div class="item_value">{{ info.STU_NAME }}</div>-->
<!--      </div>-->
      <div class="cell_item">
        <div class="item_title">EMS运单号</div>
        <div class="item_value flexStart">
          {{ info.mailing_num }}
          <img src="../../../../public/img/daInquiry_copy.png" alt="" style="width: 20px;height: 20px;margin-left: 10px;"
               @click="onCopy">
        </div>
      </div>
    </div>
    <div id="toast" class="toast">11</div>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      DataList: [],
      joined: false,
      viewResult: false,
      expired: false, //是否过期
      info:{},
    }
  },
  mounted() {
    if(this.$route.query) {
      setTimeout(()=>{
        this.getList()
      },200)
     };
  },
  methods: {
    onCopy(){
      var input = document.createElement("input"); // 创建input对象
      input.value = this.info.mailing_num; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功~");

    },
    getList(page) {
      console.log(this.$route.query)
      this.$http.post("/api/njwx_ems_by_stuNum", {
        stuNum: this.$route.query.stuNum,
        stuName: this.$route.query.stuName,
      }).then((res) => {
        if (res.data && res.data.mailing_num) {
          this.info = res.data;
        } else {
          this.showToast("抱歉，暂未查到运单号，请耐心等待~")
        }
      })
    },

    showToast(msg) {
      $("#toast").text(msg).show().animate({
        opacity: 1
      }, 200, "linear", function() {
        setTimeout(() => {
          $("#toast").animate({
            opacity: 0
          }, "linear", 3000, function() {
            $("#toast").hide()
          })
        }, 2000)
      })
    },
  }
}
</script>

<style scoped lang="less" type="text/less">
.page_daInquiry{
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #f2f4f8;
  box-sizing: border-box;
  div{box-sizing: border-box;}
  .cell_group{
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 0 5px #ccc;
    background-color: #fff;
    padding: 0 20px;
    .cell_item{
      border-bottom: 1px solid #efefef;
      padding: 15px 0;
      .item_title{
        color: #333333;
        font-size: 14px;
      }
      .item_value{
        margin-top: 10px;
        //padding-left: 4px;
        color: #909399;
        font-size: 16px;
      }
    }
    .cell_item:last-of-type{
      border: 0;
    }
  }
}

.required {
  color: orangered;
}

.notrequired {
  color: #FFFFFF;
}

.toast {
  position: fixed;
  width: 70%;
  left: 15%;
  text-align: center;
  padding: 5px 0;
  border-radius: 4px;
  bottom: 50%;
  background: orangered;
  color: #FFFFFF;
  font-size: 14px;
  opacity: 0;
  display: none;
}
</style>
